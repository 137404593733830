module.exports = [{
      plugin: require('/home/dual/my-awesome-portfolio/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":""},
    },{
      plugin: require('/home/dual/my-awesome-portfolio/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LunaLux","short_name":"LUNAX","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"content/assets/gatsby-icon.png"},
    },{
      plugin: require('/home/dual/my-awesome-portfolio/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/dual/my-awesome-portfolio/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
